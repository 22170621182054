import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import moment from 'moment';
import { MimeTypes, SourceFile as SourceFileType } from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SourceFileErrors from '../SourceFileErrors/SourceFileErrors';
import Modal from '../Modal/Modal';
import SourceFileOptions from './SourceFileOptions';
import './SourceFile.scss';

export const fileTypes = {
    excel: {
        icon: faFileExcel,
        mime: MimeTypes.excel,
    },
    csv: {
        icon: faFileCsv,
        mime: MimeTypes.csv,
    },
};

export type UploadableFileType = (typeof fileTypes)[keyof typeof fileTypes];

export interface ConfirmationMessage {
    message: string;
    title: string;
    subtitle: string;
}
export type ConfirmationCallback = (file: SourceFileType, action: 'DELETE' | 'REUPLOAD') => ConfirmationMessage | undefined;

interface Props {
    onCancelError(): void;
    onDelete?: (fileId: string) => void;
    onReupload?: (file: File[], fileId: string) => Promise<void>;
    file: SourceFileType;
    fileType: UploadableFileType;
    narrow?: boolean;
    confirmation?: ConfirmationCallback;
    isDisabled?: boolean;
}

const getFileId = (file: SourceFileType) => (file.id ? file.id : (file.uuid as string));

function SourceFile({ confirmation, file, fileType, narrow, onCancelError, onDelete, onReupload, isDisabled }: Props) {
    const [deleting, setDeleting] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState<ConfirmationMessage>();
    const [confirmed, setConfirmed] = useState(false);
    const [fileToUpload, setFileToUpload] = useState<File[]>();

    const handleDelete = useCallback(async () => {
        if (deleting) {
            return;
        }

        if (!confirmed && confirmation) {
            const message = confirmation(file, 'DELETE');
            if (message) {
                setConfirmMessage(message);
                return;
            }
        }

        setDeleting(true);
        setConfirmed(false);
        try {
            await onDelete?.(getFileId(file));
        } catch (err) {
            // ignore, covered by global error handler
        } finally {
            setDeleting(false);
        }
    }, [confirmation, confirmed, deleting, file, onDelete]);

    const handleUpload = useCallback(
        async <T extends File>(uploadedFiles: T[]) => {
            if (deleting || file.deleting) {
                return;
            }

            if (!confirmed && confirmation) {
                const message = confirmation(file, 'REUPLOAD');
                if (message) {
                    setConfirmMessage(message);
                    setFileToUpload(uploadedFiles);
                    return;
                }
            }

            onReupload?.(fileToUpload || uploadedFiles, getFileId(file));
            setConfirmed(false);
            setFileToUpload(undefined);
        },
        [confirmed, deleting, file, fileToUpload, onReupload, confirmation]
    );

    useEffect(() => {
        if (confirmed) {
            if (fileToUpload) {
                handleUpload(fileToUpload);
                return;
            }
            handleDelete();
        }
    }, [confirmed, fileToUpload, handleDelete, handleUpload]);

    const { filename, updatedAt, materialCount, filesize, progress, isModified, errors } = file;

    const processing = progress !== undefined;
    const uploading = progress !== undefined && progress < 100;
    const disabled = !!(errors || processing || deleting || file.deleting) || isDisabled;

    let status = '';
    if (processing) {
        status = 'processing...';
    }
    if (uploading) {
        status = 'uploading...';
    }
    if (deleting || file.deleting) {
        status = 'deleting...';
    }

    return (
        <Dropzone accept={fileType.mime} multiple={false} onDrop={handleUpload} noClick={true} disabled={disabled}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => {
                const rootProps = getRootProps({
                    className: cn('SourceFile', {
                        'SourceFile--dragged': isDragActive,
                        'SourceFile--dragged-error': isDragReject,
                        'SourceFile--processing': processing,
                        'SourceFile--deleting': deleting || file.deleting,
                        'SourceFile--narrow': narrow,
                        'SourceFile--errors': !!errors,
                    }),
                });
                return (
                    <>
                        <div {...rootProps} tabIndex={-1}>
                            <span className="SourceFile__fileIcon">
                                <FontAwesomeIcon icon={fileType.icon as IconProp} />
                            </span>
                            <span className="SourceFile__filename">{filename}</span>
                            <span className="SourceFile__title">
                                {isModified && <div className="error">CONTAINS MANUAL ADJUSTMENTS</div>}
                                {status}
                            </span>
                            <span className="SourceFile__meta">
                                {filesize && <div>Size {Math.round(filesize / 10000) / 100} mb</div>}
                                {updatedAt && <div>Uploaded {moment(updatedAt).calendar()}</div>}
                                {materialCount && (
                                    <div>{`Containing ${materialCount} material${materialCount > 1 ? 's' : ''}`}</div>
                                )}
                            </span>
                            <span className="SourceFile__dragOverlay">
                                {isDragActive &&
                                    (isDragReject
                                        ? 'Only drop one file if you want to re-upload'
                                        : 'Drop here to re-upload this file')}
                            </span>
                            {!disabled && (
                                <SourceFileOptions
                                    onDeleteClick={handleDelete}
                                    onReUploadClick={open}
                                    deleting={deleting || file.deleting || false}
                                />
                            )}
                            {confirmMessage && (
                                <Modal
                                    closeAction={() => {
                                        setConfirmMessage(undefined);
                                        setDeleting(false);
                                    }}
                                    cancelAction={() => {
                                        setConfirmMessage(undefined);
                                        setDeleting(false);
                                    }}
                                    okAction={() => {
                                        setConfirmMessage(undefined);
                                        setConfirmed(true);
                                    }}
                                    title={confirmMessage.title}
                                    subtitle={confirmMessage.subtitle}
                                    text={confirmMessage.message}
                                    isOpen={!!confirmMessage}
                                    okButtonText="Continue"
                                    okButtonType="button"
                                    cancelButtonText="Cancel"
                                />
                            )}
                            {processing && (
                                <div className="SourceFile__progress">
                                    <ProgressBar now={progress} striped={!uploading} animated={!uploading} />
                                </div>
                            )}
                            <input {...getInputProps()} />
                        </div>

                        {errors && <SourceFileErrors errors={errors} onCancel={onCancelError} />}
                    </>
                );
            }}
        </Dropzone>
    );
}

export default SourceFile;
