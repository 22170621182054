"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessLoadStatus = exports.ProcessIdentifiers = void 0;
var ProcessIdentifiers;
(function (ProcessIdentifiers) {
    ProcessIdentifiers["pullExternalImages"] = "PULL_EXTERNAL_IMAGES";
    ProcessIdentifiers["pullExternalPrices"] = "PULL_EXTERNAL_PRICES";
    ProcessIdentifiers["processOptionCountsFile"] = "PROCESS_OPTION_COUNT_FILE";
    ProcessIdentifiers["processHindsightFile"] = "PROCESS_HINDSIGHT_FILE";
    ProcessIdentifiers["processHindsightMaterials"] = "PROCESS_HINDSIGHT_MATERIALS";
    ProcessIdentifiers["processHindsightBuys"] = "PROCESS_HINDSIGHT_BUYS";
    ProcessIdentifiers["applyExchangeRatesForPlan"] = "APPLY_EXCHANGE_RATES_FOR_PLAN";
    ProcessIdentifiers["applyExchangeRatesForHindsightData"] = "APPLY_EXCHANGE_RATES_FOR_HINDSIGHT";
    ProcessIdentifiers["applyExchangeRatesForPlanAndHindsight"] = "APPLY_EXCHANGE_RATES_FOR_PLAN_AND_HINDSIGHT";
    ProcessIdentifiers["createDBSnapshot"] = "CREATE_DB_SNAPSHOT";
    ProcessIdentifiers["rollbackDBFromSnapshot"] = "ROLLBACK_DB_FROM_SNAPSHOT";
})(ProcessIdentifiers || (exports.ProcessIdentifiers = ProcessIdentifiers = {}));
var ProcessLoadStatus;
(function (ProcessLoadStatus) {
    ProcessLoadStatus["progress"] = "IN PROGRESS";
    ProcessLoadStatus["complete"] = "COMPLETED";
    ProcessLoadStatus["error"] = "ERROR";
    ProcessLoadStatus["deleting"] = "DELETING";
})(ProcessLoadStatus || (exports.ProcessLoadStatus = ProcessLoadStatus = {}));
