import React, { ComponentType } from 'react';
import cn from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import swooshBlack from '../../assets/img/swooshBlack.svg';
import swooshWhite from '../../assets/img/swooshWhite.svg';
import './Logo.scss';

interface Props {
    to?: string;
    app?: 'buyplan';
    black?: boolean;
    WrapperComponent?: ComponentType<LinkProps>;
    hideNameOnSmallScreen?: boolean;
}

function Logo({ to = '/', app = 'buyplan', black = true, hideNameOnSmallScreen = false, WrapperComponent = Link }: Props) {
    return (
        <WrapperComponent to={to} className={cn('Logo', `Logo--${app}`, `Logo--${black ? 'black' : 'white'}`)}>
            <img src={black ? swooshBlack : swooshWhite} alt="Nike" className="Logo__swoosh" />
            <h1 className={cn('Logo__name', { 'Logo__name--hide-small-screen': hideNameOnSmallScreen })}>{app}</h1>
        </WrapperComponent>
    );
}

export default Logo;
