import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BuyplanFiles, channels, NewRelicEventName, ProcessIdentifiers, ProcessLoadStatus } from 'buyplan-common';
import { useRequestFunction } from '../../api/useRequest';
import { getBuyPlanFiles } from '../../services/buyplanService';
import { deleteMinimumBuyFile, reuploadMinimumBuyFile, uploadMinimumBuyFile } from '../../services/minimumBuyService';
import { convertHindsightFiles, deleteHindsightFile } from '../../services/hindsightService';
import { deleteAllocationFile, reuploadAllocationFile, uploadAllocationFile } from '../../services/allocationsService';
import { deleteSalesTargetsFile, reuploadSalesTargetsFile, uploadSalesTargetsFile } from '../../services/salesTargetService';
import { useProcessStatus } from '../../services/processesService';
import ImportFiles from '../ImportFiles/ImportFiles';
import Loader from '../Loader/Loader';
import {
    deleteSeasonalHindsightFile,
    reuploadSeasonalHindsightFile,
    uploadSeasonalHindsightFile,
} from '../../services/seasonalHindsightService';
import SalesTargetsTemplateButton from '../SalesTargets/SalesTargetsTemplateButton';
import useUserSettings from '../../selectors/useUserSettings';
import useIsChannelAdmin from '../../selectors/useIsChannelAdmin';
import useSeasonSettings from '../../selectors/useSeasonSettings';
import ImportHindsightFile from './ImportHindsightFile';
import './ImportBuyplanFiles.scss';

const renderDownloadButton = (files: BuyplanFiles, activeChannelId: number) => {
    if (activeChannelId !== channels.digital.id && files.optionCountsFiles.length > 0 && files.hindsightFiles.length > 0) {
        return <SalesTargetsTemplateButton />;
    }
    if (activeChannelId === channels.digital.id && files.hindsightFiles.length > 0) {
        return <SalesTargetsTemplateButton />;
    }
    return null;
};

function ImportBuyplanFiles() {
    const { activeChannelId } = useUserSettings();
    const isChannelAdmin = useIsChannelAdmin(activeChannelId);
    const { isEditableSeason, isHistoricalSeason } = useSeasonSettings();
    const [deleted, setDeleted] = useState(false);
    const [loadingStatus, , status, fetchHindsightProcessStatus] = useProcessStatus(
        ProcessIdentifiers.processHindsightFile,
        true
    );
    const [loadingMaterialsStatus, , hindsightMaterialsStatus, fetchHindsightMaterialsProcessStatus] = useProcessStatus(
        ProcessIdentifiers.processHindsightMaterials,
        true
    );
    const [loadingBuysStatus, , hindsightBuysStatus, fetchHindsightBuysProcessStatus] = useProcessStatus(
        ProcessIdentifiers.processHindsightBuys,
        true
    );
    const [loading, error, files, fetchBuyplanFiles] = useRequestFunction(getBuyPlanFiles);

    useEffect(() => {
        fetchBuyplanFiles();
        fetchHindsightProcessStatus();
        fetchHindsightMaterialsProcessStatus();
        fetchHindsightBuysProcessStatus();
    }, [
        fetchBuyplanFiles,
        fetchHindsightProcessStatus,
        fetchHindsightMaterialsProcessStatus,
        fetchHindsightBuysProcessStatus,
        activeChannelId,
    ]);

    useEffect(() => {
        if (status?.status === ProcessLoadStatus.deleting) {
            setDeleted(true);
        }
    }, [status]);

    if (!isChannelAdmin || !isEditableSeason) {
        return <Redirect to="/buyplan" />;
    }

    return (
        <Container>
            <h1>IMPORT BUY PLAN FILES {loading && <Loader width={16} />}</h1>
            {error && (
                <div className="error">
                    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> {error.message}
                </div>
            )}
            {files && (
                <Row>
                    <Col sm={{ span: 6 }}>
                        <div className="ImportMinimumBuy">
                            <section>
                                <h3>Minimum Buy File</h3>
                                <ImportFiles
                                    files={files.minimumBuyFiles}
                                    onUpload={uploadMinimumBuyFile}
                                    onReUpload={reuploadMinimumBuyFile}
                                    onDelete={deleteMinimumBuyFile}
                                    onSuccess={() => fetchBuyplanFiles()}
                                    narrow={true}
                                    confirmation={() => ({
                                        title: 'Are you sure?',
                                        subtitle: 'This will reset your buy minimums',
                                        message: 'Resetting your minimums can affect your buy totals',
                                    })}
                                    isDisabled={isHistoricalSeason}
                                    eventTypeTracker={NewRelicEventName.minimumBuyFileUpload}
                                />
                            </section>
                        </div>
                    </Col>
                    <Col sm={{ span: 6 }}>
                        <div className="ImportHindsight">
                            <section>
                                <h3>Hindsight File (.CSV)</h3>
                                <ImportHindsightFile
                                    files={
                                        !deleted &&
                                        !loadingStatus &&
                                        !status &&
                                        !loadingMaterialsStatus &&
                                        !hindsightMaterialsStatus &&
                                        !loadingBuysStatus &&
                                        !hindsightBuysStatus
                                            ? files.hindsightFiles
                                            : convertHindsightFiles(status)
                                    }
                                    onDelete={async (id) => {
                                        setDeleted(true);
                                        await deleteHindsightFile(id);
                                        await fetchBuyplanFiles();
                                        fetchHindsightProcessStatus();
                                    }}
                                    onSuccess={() => {
                                        fetchBuyplanFiles();
                                        fetchHindsightProcessStatus();
                                    }}
                                    isDisabled={!isEditableSeason}
                                />
                            </section>
                        </div>
                    </Col>
                    <Col sm={{ span: 6 }}>
                        <div className="ImportAllocations">
                            <section>
                                <h3>Allocation File</h3>
                                <ImportFiles
                                    files={files.allocationFiles}
                                    onUpload={uploadAllocationFile}
                                    onReUpload={reuploadAllocationFile}
                                    onDelete={deleteAllocationFile}
                                    onSuccess={() => fetchBuyplanFiles()}
                                    isDisabled={isHistoricalSeason}
                                    narrow={true}
                                    eventTypeTracker={NewRelicEventName.allocationFileUpload}
                                />
                            </section>
                        </div>
                    </Col>
                    <Col sm={{ span: 6 }}>
                        <div className="ImportSeasonalHindsight">
                            <section>
                                <div className="ImportSeasonalHindsight__uploadLabel">
                                    <h3 className="ImportSeasonalHindsight__title">Seasonal Hindsight File</h3>
                                    {!files.hindsightFiles.length && (
                                        <h5 className="ImportSeasonalHindsight__warning">
                                            Please upload a Hindsight File first
                                        </h5>
                                    )}
                                </div>
                                <ImportFiles
                                    files={files.seasonalHindsightFiles}
                                    onDelete={deleteSeasonalHindsightFile}
                                    onReUpload={reuploadSeasonalHindsightFile}
                                    onSuccess={() => fetchBuyplanFiles()}
                                    onUpload={uploadSeasonalHindsightFile}
                                    isDisabled={!files.hindsightFiles.length || isHistoricalSeason}
                                    eventTypeTracker={NewRelicEventName.seasonalHindsightFileUpload}
                                />
                            </section>
                        </div>
                    </Col>
                    <Col sm={{ span: 6 }}>
                        <div className="ImportSalesTargets">
                            <section>
                                <div className="ImportSalesTargets__buttonContainer">
                                    <h3>Sales Targets File</h3>
                                    {!files.salesTargetFiles.length && renderDownloadButton(files, activeChannelId)}
                                </div>
                                <ImportFiles
                                    files={files.salesTargetFiles}
                                    onUpload={uploadSalesTargetsFile}
                                    onReUpload={reuploadSalesTargetsFile}
                                    onDelete={deleteSalesTargetsFile}
                                    onSuccess={() => fetchBuyplanFiles()}
                                    narrow={true}
                                    isDisabled={!isEditableSeason}
                                    eventTypeTracker={NewRelicEventName.salesTargetsFileUpload}
                                />
                            </section>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default ImportBuyplanFiles;
