import { parse, Parser } from 'papaparse';
import { v4 } from 'uuid';
import { difference, groupBy } from 'lodash';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import addErrors from 'ajv-errors';
import {
    hindsightBuySchema,
    hindsightMaterialSchema,
    HindsightYearType,
    ValidationError,
    UploadHindsightMaterial,
    UploadHindsightBuy,
    typeOfOrderOptions,
    launchFlagOptions,
} from 'buyplan-common';

interface CsvRow {
    'store no': string;
    retailer: string;
    'material code': string;
    division: string;
    'condensed category final': string;
    'sales units': string;
    'weeks on sale': string;
    ros: string;
    'sell thru': string;
    'family final': string;
    'emea family': string;
    'model final': string;
    'sales net revenue': string;
    'invest units': string;
    'color description': string;
    'item name final': string;
    year: string;
    'style code': string;
    age: string;
    gender: string;
    'aa/icon': string | null;
    'gel/quickstrike': string | null;
    silhouette: string;
    'cc segment': string;
    'launch flag': string | null;
    'cc consumer': string;
    'margin usd': string;
    'margin percentage': string;
    brand: string;
    franchise: string;
    'merch class': string;
    'consumer use': string;
    'emea dimension': string;
    'emea silo': string;
    'emea sub-franchise': string;
    'emea collection name': string;
    'silhouette type': string;
    '3rd party vendor (licensee)': string;
    'fields of play': string;
    'features (sustainability tier)': string;
    'emea football plates': string;
    'emea business model': string;
}

const requiredHeaders = [
    'store no',
    'retailer',
    'material code',
    'division',
    'condensed category final',
    'sales units',
    'weeks on sale',
    'ros',
    'sell thru',
    'family final',
    'emea family',
    'model final',
    'sales net revenue',
    'invest units',
    'color description',
    'item name final',
    'year',
    'style code',
    'age',
    'gender',
    'aa/icon',
    'gel/quickstrike',
    'silhouette',
    'cc segment',
    'launch flag',
    'cc consumer',
    'margin usd',
    'margin percentage',
    'brand',
    'franchise',
    'merch class',
    'consumer use',
    'emea dimension',
    'emea silo',
    'emea sub-franchise',
    'emea collection name',
    'silhouette type',
    '3rd party vendor (licensee)',
    'fields of play',
    'features (sustainability tier)',
    'emea football plates',
    'emea business model',
];

/**
 * Parses a number from the hindsight file. A "-" is interpreted as 0.
 * Returns the original value if the input string could not be parsed.
 */
const parseNumber = (input?: string) => {
    if (input && input.trim() === '-') {
        return 0;
    }
    return Number.isNaN(Number(input)) ? input : Number(input);
};

const transformToHindsightMaterial = (input: CsvRow) => ({
    materialCode: input['material code']?.trim().toUpperCase(),
    division: input.division?.trim().toUpperCase(),
    category: input['condensed category final']?.trim().toUpperCase(),
    family: input['family final']?.trim().toUpperCase(),
    emeaFamily: input['emea family']?.trim().toUpperCase(),
    model: input['model final']?.trim().toUpperCase(),
    age: input.age?.trim().toUpperCase(),
    gender: input.gender?.trim().toUpperCase(),
    colorDescription: input['color description']?.trim().toUpperCase(),
    description: input['item name final']?.trim().toUpperCase(),
    style: input['style code']?.trim().toUpperCase(),
    silhouette: input.silhouette?.trim().toUpperCase(),
    segment: input['cc segment']?.trim().toUpperCase(),
    yearType: input.year?.trim().toUpperCase(),
    launchFlag: input['launch flag']?.trim().toUpperCase(),
    consumer: input['cc consumer']?.trim().toUpperCase(),
    brand: input.brand?.trim().toUpperCase(),
    franchise: input.franchise?.trim().toUpperCase(),
    merchClass: input['merch class']?.trim().toUpperCase(),
    consumerUse: input['consumer use']?.trim().toUpperCase(),
    emeaDimension: input['emea dimension']?.trim().toUpperCase(),
    emeaSilo: input['emea silo']?.trim().toUpperCase(),
    emeaSubFranchise: input['emea sub-franchise']?.trim().toUpperCase(),
    emeaCollectionName: input['emea collection name']?.trim().toUpperCase(),
    silhouetteType: input['silhouette type']?.trim().toUpperCase(),
    thirdParty: input['3rd party vendor (licensee)']?.trim().toUpperCase(),
    fieldsOfPlay: input['fields of play']?.trim().toUpperCase(),
    features: input['features (sustainability tier)']?.trim().toUpperCase(),
    emeaFootballPlates: input['emea football plates']?.trim().toUpperCase(),
    emeaBusinessModel: input['emea business model']?.trim().toUpperCase(),
});

const transformToHindsightBuy = (input: CsvRow) => ({
    storeNumber: input['store no'],
    partner: input.retailer,
    salesUnits: parseNumber(input['sales units']),
    weeksOnSale: parseNumber(input['weeks on sale']),
    rateOfSale: parseNumber(input.ros),
    sellThrough: parseNumber(input['sell thru']),
    salesNetRevenue: parseNumber(input['sales net revenue']),
    investUnits: parseNumber(input['invest units']),
    marginUsd: parseNumber(input['margin usd']),
    marginPercentage: parseNumber(input['margin percentage']),
});

type Result = {
    materialsLY: UploadHindsightMaterial[];
    buysLY: UploadHindsightBuy[];
    materialsLLY: UploadHindsightMaterial[];
    buysLLY: UploadHindsightBuy[];
};

export class HindsightValidationError extends Error {
    meta: {
        hasErrors: boolean;
        type: string;
        validationErrors: ValidationError[];
    };

    constructor(message: string, validationResult: ValidationError[]) {
        super(message);
        this.meta = {
            hasErrors: true,
            type: 'HindsightValidationError',
            validationErrors: validationResult,
        };
    }
}

export const MAX_ERRORS = 1000;

/**
 * Groups the same errors into 1 error with an array of row numbers.
 */
const groupValidationErrors = (errors: ValidationError[]) => {
    const groupedErrors = groupBy(errors, 'error');
    const result = [] as ValidationError[];
    Object.entries(groupedErrors).forEach(([error, values]) => {
        const numbers = values.reduce((acc, { rowNumbers }) => {
            if (rowNumbers && rowNumbers.length > 0) {
                return acc.concat(rowNumbers);
            }
            return acc;
        }, [] as number[]);
        result.push({
            error,
            rowNumbers: numbers.length > 0 ? numbers : undefined,
        });
    });
    return result;
};

const parseTypeOfOrder = (aaIcon: string | null, gelQuickstrike: string | null) => {
    let typeOfOrder: string;
    if (aaIcon === null && gelQuickstrike === null) {
        typeOfOrder = typeOfOrderOptions.authorizedFutures;
    }

    if (aaIcon?.toString().toLowerCase() === 'aa') {
        typeOfOrder = typeOfOrderOptions.alwaysAvailable;
    } else if (gelQuickstrike?.toString().toLowerCase() === 'qs') {
        typeOfOrder = typeOfOrderOptions.quickStrike;
    } else {
        typeOfOrder = typeOfOrderOptions.authorizedFutures;
    }

    return typeOfOrder;
};

/**
 * Lauch flag column in the hindsight file is only used by the Launch team. According to their processes,
 * YES value should be mapped to 'L' launch flag, and NO value should be mapped to 'D' launch flag.
 * If there is no launch flag applied to the material, this field will be left blank.
 */
const parseLaunchFlag = (input?: string | null) => {
    if (!input) return null;
    const flag = input.trim().toUpperCase();
    if (flag === 'YES') {
        return launchFlagOptions.l;
    }
    if (flag === 'NO') {
        return launchFlagOptions.d;
    }
    return null;
};

const ajv = new Ajv({ allErrors: true });
addFormats(ajv);
addKeywords(ajv);
addErrors(ajv);

export const parseHindsightFile = async (file: File, fileId: string): Promise<Result> => {
    const validateBuy = ajv.compile(hindsightBuySchema);
    const validateMaterial = ajv.compile(hindsightMaterialSchema);

    return new Promise((resolve, reject) => {
        // Two separate results are kept (LY and LLY) to prevent too much copying of data and filling up memory. With a large file (200MB), it already gets over 1GB
        const materialsLY: { [key: string]: UploadHindsightMaterial } | undefined = {};
        const buysLY: UploadHindsightBuy[] = [];
        const materialsLLY: { [key: string]: UploadHindsightMaterial } | undefined = {};
        const buysLLY: UploadHindsightBuy[] = [];
        const errors: ValidationError[] = [];
        let rowNumber = 1;

        parse(file, {
            worker: false, // setting this to true makes the process slower and prevents transformHeader to be used
            header: true,
            skipEmptyLines: true,
            transformHeader(header) {
                return header.trim().toLowerCase();
            },
            // When using generics here, it expects an array in row.data. But we get just one object, so it has to be typed as any.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            step(row: any, parser: Parser) {
                rowNumber++;
                const data = row.data as unknown as CsvRow;

                // The "year" column has two values "TY" (this year) and "LY" (last year). From a hindsighting perspective, the year that they're working on is called "TY",
                // but from and assortment planning perspective, that is the year before the current year. So hindsight "TY" becomes "LY" and "LY" becomes "LLY".
                const isLY = data.year === 'TY';

                const addError = (error: string | undefined, isHeaderError = false) => {
                    if (error) {
                        errors.push({ rowNumbers: isHeaderError ? [1] : [rowNumber], error });
                        if (errors.length >= MAX_ERRORS) {
                            parser.abort();
                            reject(new HindsightValidationError('invalid hindsight file', errors));
                        }
                    }
                };

                /*
                Validate that required headers are provided. This should be run only once, against the first line of data (row 2).
                Headers data is stored in meta of every row
                */
                if (rowNumber === 2) {
                    const missingHeaders = difference(requiredHeaders, row.meta.fields);
                    if (missingHeaders.length > 0) {
                        addError(`Missing required headers: ${missingHeaders.join(', ').toUpperCase()}`, true);
                        reject(new HindsightValidationError('Invalid hindsight file', groupValidationErrors(errors)));
                    }
                }

                const materialCode = data['material code'];
                const aaIcon = data['aa/icon'];
                const gelQuickstrike = data['gel/quickstrike'];
                const launchFlag = data['launch flag'];
                let material: UploadHindsightMaterial = isLY ? materialsLY[materialCode] : materialsLLY[materialCode];

                if (!material) {
                    const typeOfOrder = parseTypeOfOrder(aaIcon, gelQuickstrike);
                    const mappedLaunchFlag = parseLaunchFlag(launchFlag);
                    material = {
                        ...transformToHindsightMaterial(data),
                        id: v4(),
                        hindsightFileId: fileId,
                        yearType: isLY ? HindsightYearType.LY : HindsightYearType.LLY,
                        typeOfOrder,
                        launchFlag: mappedLaunchFlag,
                    };
                    if (!validateMaterial(material)) {
                        if (validateMaterial.errors) {
                            addError(validateMaterial.errors[0].message);
                        } else {
                            reject(
                                new HindsightValidationError(
                                    'Issue encountered during hindsight material validation',
                                    groupValidationErrors(errors)
                                )
                            );
                        }
                    } else if (isLY) {
                        materialsLY[materialCode] = material;
                    } else {
                        materialsLLY[materialCode] = material;
                    }
                }

                const buy = {
                    ...transformToHindsightBuy(data),
                    hindsightMaterialId: material.id,
                    yearType: material.yearType,
                };
                if (!validateBuy(buy)) {
                    if (validateBuy.errors) {
                        addError(validateBuy.errors[0].message);
                    } else {
                        reject(
                            new HindsightValidationError(
                                'Issue encountered during hindsight buy validation',
                                groupValidationErrors(errors)
                            )
                        );
                    }
                } else if (isLY) {
                    buysLY.push(buy as UploadHindsightBuy);
                } else {
                    buysLLY.push(buy as UploadHindsightBuy);
                }
            },
            async complete() {
                if (errors.length > 0) {
                    reject(new HindsightValidationError('Invalid hindsight file', groupValidationErrors(errors)));
                } else {
                    resolve({
                        materialsLY: Object.values(materialsLY),
                        buysLY,
                        materialsLLY: Object.values(materialsLLY),
                        buysLLY,
                    });
                }
            },
        });
    });
};
