import {
    HindsightYearType,
    UploadHindsightBuy,
    UploadHindsightMaterial,
    ProcessStatus,
    ProcessLoadStatus,
    HindsightFileErrors,
} from 'buyplan-common';
import { request, OnProgress } from '../api/api';
import { sanitizeS3FileName } from '../helpers/utils';
import { asyncRequest, upload } from './uploadFileService';

export const addHindsightMaterials = (
    data: UploadHindsightMaterial[],
    fileId: string,
    onProgress: OnProgress,
    fetchStatus?: () => Promise<{ data: ProcessStatus }>
) => {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles/${fileId}/materials`,
        data,
    };

    return asyncRequest({ options, onProgress, fetchStatus });
};

export const addHindsightBuys = (
    data: { buys: UploadHindsightBuy[]; yearType: HindsightYearType },
    fileId: string,
    onProgress: OnProgress,
    fetchStatus?: () => Promise<{ data: ProcessStatus }>
) => {
    const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles/${fileId}/buys`,
        data,
    };

    return asyncRequest({ options, onProgress, fetchStatus });
};

export const uploadHindsightFile = async (
    file: File,
    uuid: string,
    onProgress: OnProgress,
    fetchStatus?: () => Promise<{ data: ProcessStatus }>
) => {
    const sanitizedFileName = sanitizeS3FileName(file.name);
    const uniqueFileName = `${uuid}_${Date.now()}-${sanitizedFileName}`;
    const destinationKey = `hindsight-files/${uniqueFileName}`;
    const requestOptions = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles`,
        data: {
            file: {
                originalname: file.name,
                filename: uniqueFileName,
                size: file.size,
                mimetype: file.type,
            },
            uuid,
        },
    };

    return upload({
        file,
        destinationKey,
        mimeType: file.type,
        dryRun: file.name.includes('no_upload'),
        onProgress,
        suppressGlobalError: true,
        uploadPostProcessWith: requestOptions,
        fetchStatus,
    });
};

export const convertHindsightFiles = (status?: ProcessStatus) => {
    if (!status) return [];
    const { uuid, filesize, filename, mimetype, error } = JSON.parse((status?.meta as string) || '{}') as {
        uuid: string;
        filesize: number;
        filename: string;
        mimetype: string;
        error: string;
    };
    if (status?.status === ProcessLoadStatus.deleting)
        return [
            {
                uuid,
                filesize,
                filename,
                mimetype,
                deleting: true,
            },
        ];
    if (status?.status === ProcessLoadStatus.error)
        return [
            {
                uuid,
                filesize,
                filename,
                mimetype,
                errors: {
                    system: error,
                } as unknown as HindsightFileErrors,
            },
        ];
    if (status?.status === ProcessLoadStatus.progress) return [{ uuid, filesize, filename, mimetype, progress: 100 }];
    return [{ uuid, filesize, filename, mimetype }];
};

export const deleteHindsightFile = (uuid: string) => {
    const requestOptions = {
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_ENDPOINT}/hindsightfiles/${uuid}`,
    };

    return request(requestOptions);
};

export const searchHindsight = (partnerId: string, searchText: string) => {
    const endPoint = `${process.env.REACT_APP_API_ENDPOINT}/hindsight/${partnerId}/search`;
    const requestOptions = {
        method: 'GET',
        url: endPoint,
        queryParams: { searchText },
    };

    return request<{ data: { materialCode: string; description: string; colorDescription: string }[] }>(requestOptions);
};
