"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hindsightBuySchema = void 0;
exports.hindsightBuySchema = {
    $id: 'https://buyplan.nike.com/hindsightBuySchema.json',
    $schema: 'http://json-schema.org/schema#',
    type: 'object',
    additionalProperties: false,
    properties: {
        storeNumber: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        partner: {
            type: 'string',
            minLength: 1,
            maxLength: 45,
        },
        salesUnits: {
            type: 'integer',
        },
        weeksOnSale: {
            type: 'integer',
        },
        rateOfSale: {
            type: 'number',
        },
        sellThrough: {
            type: 'number',
        },
        salesNetRevenue: {
            type: 'number',
        },
        investUnits: {
            type: 'integer',
        },
        hindsightMaterialId: {
            type: 'string',
            format: 'uuid',
        },
        yearType: {
            type: 'integer',
            minimum: 0,
            maximum: 1,
        },
        marginUsd: {
            type: 'number',
        },
        marginPercentage: {
            type: 'number',
        },
    },
    errorMessage: {
        required: {
            storeNumber: 'Store number is required',
            partner: 'Partner is required',
            salesUnits: 'Sales units is required',
            weeksOnSale: 'Weeks on sale is required',
            rateOfSale: 'Rate of sale is required',
            sellThrough: 'Sell through is required',
            salesNetRevenue: 'Sales net revenue is required',
            investUnits: 'Invest units is required',
            hindsightMaterialId: 'Hindsight material ID is required',
            yearType: 'Year type is required',
            marginUsd: 'Margin USD is required',
            marginPercentage: 'Margin percentage is required',
        },
        properties: {
            storeNumber: 'Store number must be a string with a minimum length of 1 and a maximum length of 45',
            partner: 'Partner must be a string with a minimum length of 1 and a maximum length of 45',
            salesUnits: 'Sales units must be a number',
            weeksOnSale: 'Weeks on sale must be a number',
            rateOfSale: 'Rate of sale must be a number',
            sellThrough: 'Sell through must be a number',
            salesNetRevenue: 'Sales net revenue must be a number',
            investUnits: 'Invest units must be a number',
            hindsightMaterialId: 'Hindsight material ID must be a UUID',
            yearType: 'Year type must be a 0 or 1',
            marginUsd: 'Margin USD must be a number',
            marginPercentage: 'Margin percentage must be a number',
        },
    },
    required: [
        'storeNumber',
        'partner',
        'salesUnits',
        'weeksOnSale',
        'rateOfSale',
        'sellThrough',
        'salesNetRevenue',
        'investUnits',
        'hindsightMaterialId',
        'yearType',
        'marginUsd',
        'marginPercentage',
    ],
};
