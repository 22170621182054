import { ProcessStatus, ProcessLoadStatus } from 'buyplan-common';
import axios, { AxiosProgressEvent } from 'axios';
import { globalErrorHandler, OnProgress, request, RequestOptions, ResponseError } from '../api/api';
import { recursionGetStatus } from './processesService';

export interface UploadInput {
    file: File;
    destinationKey: string;
    mimeType: string;
    dryRun?: boolean;
    onProgress?: OnProgress;
    uploadPostProcessWith?: RequestOptions;
    suppressGlobalError?: boolean;
    fetchStatus?: () => Promise<{ data: ProcessStatus }>;
}

export type UploadOutput = { data: { filename: string } | [] };

export const getSignedUrl = async (name: string, type: string) => {
    const response = await request<{ data: { status: string; url: string } }>({
        method: 'GET',
        // '&' in the end because some middleware may add extra query parameters to the query line and it corrupts signed url:
        // url type generated for line like: type=${fileType}?<number of extra parameters>
        url: `${process.env.REACT_APP_API_ENDPOINT}/get-signed-url?name=${name}&type=${type}&`,
    });

    return !response?.data || response.data.status === 'error' ? null : response.data.url;
};

export const uploadToStorage = async (
    file: File,
    destinationKey: string,
    mimeType: string,
    dryRun?: boolean
): Promise<void> => {
    if (dryRun) {
        // external file upload disabled.
        return;
    }

    const url = await getSignedUrl(destinationKey, mimeType);

    if (!url) {
        throw Error(`missing upload url for ${destinationKey}. skipping file upload`);
    }

    try {
        // api.request adds a lot of extra parameters and headers that require either filtering or extra-processing
        // so using pure fetch makes it easier
        await fetch(url, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': mimeType,
            },
            credentials: 'omit',
        });
    } catch (err) {
        globalErrorHandler(err as ResponseError);
    }
};

export const upload = async (input: UploadInput): Promise<UploadOutput> => {
    const { file, destinationKey, mimeType, dryRun, onProgress, uploadPostProcessWith, suppressGlobalError, fetchStatus } =
        input;
    const defaultUploadResult = {
        data: {
            filename: file.name,
        },
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const changeProgress = onProgress ? (progress: number) => onProgress(progress) : (_: number) => ({});

    if (dryRun) {
        // external file upload disabled.
        changeProgress(100);
        return defaultUploadResult;
    }

    const url = await getSignedUrl(destinationKey, mimeType);

    if (!url) {
        changeProgress(1);
        throw Error(`missing upload url for ${destinationKey}. skipping..`);
    }

    let progress = 10; // 10% - for signed url, 70% - for actual file upload, 20% - for data processing
    changeProgress(progress);

    try {
        await axios.put(url, file, {
            headers: {
                'Content-Type': mimeType,
            },
            withCredentials: false,
            onUploadProgress: (p: AxiosProgressEvent) => {
                progress += (70 * p.loaded) / (p.total || 1);
                changeProgress(progress);
            },
        });
    } catch (err) {
        changeProgress(1);
        globalErrorHandler(err as ResponseError);
    }

    if (!uploadPostProcessWith) {
        changeProgress(100);
        return defaultUploadResult;
    }

    let result;
    try {
        result = await request<UploadOutput>(uploadPostProcessWith, suppressGlobalError);
    } catch (err) {
        changeProgress(1);
        throw err;
    }
    if (fetchStatus) {
        const res = await recursionGetStatus(fetchStatus);
        if (res?.data?.status === ProcessLoadStatus.error) {
            changeProgress(1);
            throw new Error(JSON.parse((res.data.meta || '{}') as string).error);
        }
    }
    changeProgress(100);

    return result;
};

export interface AsyncRequestInput {
    options: RequestOptions;
    onProgress?: OnProgress;
    fetchStatus?: () => Promise<{ data: ProcessStatus }>;
}

export type AsyncRequestOutput = { data: { count: number } };

export const asyncRequest = async (input: AsyncRequestInput): Promise<AsyncRequestOutput> => {
    const { options, onProgress, fetchStatus } = input;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const changeProgress = onProgress ? (progress: number) => onProgress(progress) : (_: number) => ({});
    const progress = 10; // 10% - for signed url, 70% - for actual file upload, 20% - for data processing

    changeProgress(progress);

    let result;
    try {
        result = await request<AsyncRequestOutput>(options);
    } catch (err) {
        changeProgress(1);
        throw err;
    }
    if (fetchStatus) {
        const res = await recursionGetStatus(fetchStatus);
        if (res?.data?.status === ProcessLoadStatus.error) {
            changeProgress(1);
            throw new Error(JSON.parse((res.data.meta || '{}') as string).error);
        }
    }
    changeProgress(100);

    return result;
};
