import React from 'react';

export default function () {
    return (
        <div>
            <h2>Support</h2>
            <p>
                In case you encounter issues with BuyPlan or have a support request you can report this in Nike Now by
                selecting
                {` `}
                <code>Buyplan (EMEA)</code> as Service. Our support office will find the right knowledge capabilities and/or
                decision makers for the issue and will contact you.
            </p>
            <p>
                <a
                    href="https://niketech.service-now.com/nikenow/?id=nike_incident"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="https://niketech.service-now.com/1c4fb96c1b5c3d107f1dfd15cc4bcb3d.iix"
                        width="200"
                        alt="Nike Now"
                    />
                </a>
                <br />
                If you need help reporting a request you can find a detailed guide{' '}
                <a href="https://nike.box.com/s/nujlcb2tk0mzjs4zt4fl7auebsisuibt">here</a>.
            </p>
        </div>
    );
}
