import React from 'react';

export default function () {
    return (
        <div>
            <h2>Roles &amp; Rights</h2>
            <p>
                There are four different roles defined in the application: read-only users, users, channel admins and app
                admins (super users).
            </p>
            <p>
                <b>Read-only users</b> are able to view the assortment tool, view the buy plan and use the download feature.
                Read-only users can not make any updates within the Buyplan application.
            </p>
            <p>
                <b>Users</b> are able to work in the assortment tool, update the buy plan and use the download feature.
            </p>
            <p>
                <b>Channel admins</b> can only manage their owned Channels, and they have the permission to setup a season by
                uploading merchandise files, late adds, minimum buys and hindsight data. Channel admins are also allowed to
                change the rights of other users in that channel and grant/revoke channel admin rights.
            </p>
            <p>
                <b>App admins</b> (or super users) can grant Channel admin rights to users.
            </p>
            <h3>Creating and updating a user profile</h3>
            <p>
                Add a new user profile by entering the e-mail address of the person. After that the profile can be limited on
                single Channel partners, categories and divisions. By not limiting on one of the properties the user is allow
                to see everthing.{' '}
                <em>
                    E.g. Partner:Ardensport, Category:(not limited), Division:FOOTWEAR. would mean that the user can work on
                    all categories for Ardensport, but only in the FOOTWEAR division.
                </em>
            </p>
            <h3>Rights and available data</h3>
            <p>
                All the data in the application is filtered automatically, leaving out the properties or the entire channels
                the user has limitations on. An example would be that a user is only able to see basketball footwear and
                apparel for Ardensport and Percassi.
            </p>
            <p>
                To prevent locking yourself out, it is not allowed to remove your own user profile, or revoke your own admin
                permissions.
            </p>{' '}
        </div>
    );
}
